import { isPlatformBrowser } from '@angular/common';
import * as i0 from '@angular/core';
import { PLATFORM_ID, Injectable, Inject } from '@angular/core';
import { of } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import * as i1 from '@angular/cdk/layout';
class IsMobileService {
  constructor(breakPointObserver, platformId) {
    this.breakPointObserver = breakPointObserver;
    this.platformId = platformId;
    this.isMobile = false;
    this.isMobile$ = this.breakPointObserver.observe(['(max-width: 1023px)']).pipe(map(({
      matches
    }) => matches), distinctUntilChanged());
  }
  checkMobile() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 1024) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }
    return this.isMobile;
  }
  checkMobile$(forceDevice) {
    if (forceDevice !== undefined) return of(forceDevice);
    return this.isMobile$;
  }
  static {
    this.ɵfac = function IsMobileService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || IsMobileService)(i0.ɵɵinject(i1.BreakpointObserver), i0.ɵɵinject(PLATFORM_ID));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: IsMobileService,
      factory: IsMobileService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IsMobileService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.BreakpointObserver
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { IsMobileService };
