export const environment = {
  production: true
};

export const ENVIRONMENT = 'qa';

export const SEO = 'https://apis-prod.cocha.cloud/landings/admin-packages';
export const SEO_FLIGHTS = 'https://apis-prod.cocha.cloud/landings/admin-flights';
export const SEO_HOTELS = 'https://apis-prod.cocha.cloud/landings/admin-hotels';
export const SEO_MULTIFLIGHTS = 'https://apis-prod.cocha.cloud/landings/admin-multiflights';
export const WAPP_FLIGHTS = 'https://www-qa.cocha.com/resultado/vuelos';
export const WAPP_PKG = 'https://www-qa.cocha.com/resultado/vuelo-hotel/h';
export const WAPP_HOTELS = 'https://www-qa.cocha.com/resultado/hoteles';
export const WAPP_TRIP_ASSISTANCE = 'https://www-qa.cocha.com/resultado/asistencia-al-viajero';

export const webMonitorUrl = 'https://static-cdn-qa.cocha.cloud/webhealth-mon';
export const COCHA_URL = 'https://www-qa.cocha.com';

export const LANDINGS_URL = {
  landings: 'https://apis-prod.cocha.cloud/landings/get-admin-qa',
  landing_alliances_images: 'https://storage.googleapis.com/gcp-production-cdn/cms/images/1_home3/alianzas',
  landing_background: 'https://storage.googleapis.com/gcp-production-cdn/cms/images/home/background.jpg',
};
export const AGENCIES = 'https://apis-prod.cocha.cloud/configs/config-agencies';
export const SUBSCRIBE_URL = 'https://us-east1-cocha-qa.cloudfunctions.net/subscriptionOffer';
export const ADMIN_LANDINS = 'sites-qa-int.cocha.cloud,webs-qa-int.cocha.cloud';
export const HOLIDAY_EXCHANGE_RATE = 'https://apis-qa.cocha.cloud/api-admin-config/configs/tipo-de-cambio/smart';
export const AB_HOLIDAY = 'https://apis-prod.cocha.cloud/api-admin-config/configs/green-blue-vh-redirect/config-1';
export const ALLIANCES = 'https://apis-prod.cocha.cloud/api-admin-config/configs/alianzas/alianzas-disponibles';
export const ALLIANCES_CONFIG = 'https://apis-prod.cocha.cloud/configs/alliances-campaigns-qa';
export const COUPONS_DISCOUNT = 'https://apis-prod.cocha.cloud/api-admin-config/configs/coupons/defaultw?env=Stage';
export const GOOGLE_PROVIDER_ID = '239664736751-h1dijuldq182bsjb2opg4o0dm1l2r9r5.apps.googleusercontent.com';
export const COUPON_BENEFITS = 'https://apis-prod.cocha.cloud/api-admin-config/admin/coupon-benefits';
export const SIGNIN = 'https://apis-prod.cocha.cloud/api-manager-external-auth/login';
export const DELETE_ACCOUNT = 'https://apis-prod.cocha.cloud/api-manager-external-auth/user-delete';
export const MY_ACCOUNT_CONFIG = 'https://apis-prod.cocha.cloud/api-admin-config/configs/mi-cuenta/default?env=Stage';